<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card" id="invoice-preview-card" style="display: block; width: 100%">
          <b-card-body class="invoice-padding pb-0">
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <!-- Header: Left Content -->
              <Logo />

              <!-- Header: Right Content -->
              <div class="invoice-number-date mt-md-0 mt-2">
                <div class="d-flex align-items-center justify-content-md-end mb-1">
                  <h4>
                    Quotation
                    <span class="invoice-number">#{{ quotationId.quotationNo }}</span>
                  </h4>
                </div>

                <div class="d-flex align-items-center justify-content-md-between mb-2">
                  <span class="title"> Created Date: </span>

                  <flat-pickr v-model="quotationId.createdOn" class="form-control invoice-edit-input" disabled />
                </div>
                <div class="d-flex align-items-center justify-content-md-between">
                  <span class="title"> Due Date: </span>
                  <flat-pickr v-model="quotationId.dueDate" class="form-control invoice-edit-input" disabled />
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Invoice Client & Payment Details -->
          <b-card-body class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col cols="12" xl="6" class="p-0">
                <h6 class="mb-2">Send To:</h6>
                <div class="mt-1">
                  <b-card-text class="mb-25">
                    {{ whenSelected.otherName }}
                  </b-card-text>
                  <b-card-text class="mb-25">
                    {{ whenSelected.addressLine1 }}
                    {{ whenSelected.addressLine2 }}
                  </b-card-text>
                  <b-card-text class="mb-25">
                    {{ whenSelected.city }}
                  </b-card-text>
                  <b-card-text class="mb-25">
                    {{ whenSelected.tel }}
                  </b-card-text>
                  <b-card-text class="mb-0">
                    {{ whenSelected.email }}
                  </b-card-text>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <!-- Invoice Description: Table -->
          <b-table responsive :fields="fields" :items="quotationId.quotationItems">
            <template #cell(cost)="data">
              Rs {{ parseFloat(data.item.cost).toFixed(2) }}
            </template>
            <template #cell(price)="data">
              Rs
              {{
                (
                  data.item.cost * data.item.quantity -
                  (data.item.discount === null ? 0 : data.item.discount)
                ).toFixed(2)
              }}
            </template>
            <template #cell(discount)="data">
              Rs
              {{
                (data.item.discount === null ? 0 : data.item.discount).toFixed(
                  2
                )
              }}
            </template>
          </b-table>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col cols="12" md="6" class="mt-md-0 mt-3" order="2" order-md="1">
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">Salesperson :</span>
                  <span class="ml-75">{{ quotationId.salesPersonId }} </span>
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Subtotal:</p>
                    <p class="invoice-total-amount">
                      Rs {{ parseFloat(quotationId.subTotal).toFixed(2) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">VAT</p>
                    <p class="invoice-total-amount">
                      Rs {{ parseFloat(quotationId.tax).toFixed(2) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Discount</p>
                    <p class="invoice-total-amount">
                      Rs {{ parseFloat(quotationId.discount).toFixed(2) }}
                    </p>
                  </div>
                  <hr class="my-50" />
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Total:</p>
                    <p class="invoice-total-amount">
                      Rs {{ parseFloat(quotationId.total).toFixed(2) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span>{{ quotationId.notes }}</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <!-- Button: DOwnload -->

          <!-- Button: Print -->
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class="mb-75" block
            @click="printQuotation">
            Print / Download
          </b-button>

          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" class="mb-75" block
            @click="convertProforma" v-if="whenSelected.status >= 1">
            Convert to Proforma
          </b-button>

        </b-card>
      </b-col>
    </b-row>
    <b-modal id="modal-addWarehouse" v-model="showAddWarehouseModal" @hide="onModalHide" centered>
      <span for="invoice-data-sales-person" class="text-nowrap mr-50"
        >Choose Warehouse:</span
      >
      <b-form-select v-model="warehouseId" :options="optionsWarehouse" />
      <template #modal-footer>
        <b-button @click="continueConvertProforma">Okay</b-button>
      </template>
</b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTable,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BLink,
  BFormSelect,
  BSpinner,
  BModal,
  VBModal,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import html2pdf from "html2pdf.js";
import api from "@/api/api-v2-config";
import { mapActions, mapGetters } from "vuex";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    Ripple,
  },
  components: {
    flatPickr,
    BRow,
    BCol,
    BTable,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    VBToggle,
    BFormSelect,
    Logo,
    BSpinner,
    BTable,
    BLink,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple
  },
  data() {
    return {
      id: this.$route.params.id,
      leadClientId: "",
      salesPersonId: "",
      dueDate: "",
      subTotal: "",
      discount: "",
      tax: "",
      total: "",
      notes: "",
      status: "",
      whenSelected: [],
      paymentMethod: "",
      quotationItems: {
        id: "",
        quotationId: "",
        itemId: "",
        quantity: "",
        price: "",
        cost: "",
        isActive: "",
      },
      warehouseId: "",
      optionsWarehouse: [],
      fields: [
        { key: "id", span: "ID", thClass: "d-none", tdClass: "d-none" }, //thClass and tdClass used to hide fields
        {
          key: "quotationId",
          span: "QUOTATION_ID",
          thClass: "d-none",
          tdClass: "d-none",
        },
        { key: "itemName", span: "Item Name" },
        { key: "description", span: "DESCRIPTION" },
        { key: "cost", span: "Unit Price (Rs)" },
        { key: "quantity", span: "QUANTITY" },
        { key: "discount", span: "DISCOUNT" },
        { key: "price", span: "Total Price (Rs)" },
      ],
      showAddWarehouseModal: false,
      continueConversion: false,
    };
  },
  computed: {
    ...mapGetters("quotationModule", {
      quotationId: "quotationId",
      loading: "loading",
    }),
    ...mapGetters("customerModule", {
      custId: "custId",
      loading: "loading",
    }),
    ...mapGetters("salesPersonModule", {
      SalesPersonList: "salesperson",
      loading: "loading",
    }),
    ...mapGetters("leadModule", {
      leadList: "leads",
      leadId: "leadId",
      loadingCustomer: "loading",
    }),
    ...mapGetters("warehouseModule", {
      warehouse: "warehouse",
      loading: "loading",
    }),
  },
  methods: {
    printQuotation() {
      window.print();
    },
    ...mapActions("customerModule", ["getCustomersByIDAction"]),
    ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),
    ...mapActions("quotationModule", ["getQuotationByIdAction"]),
    ...mapActions("leadModule", ["getLeadListAction", "getLeadByIDAction"]),
    ...mapActions("warehouseModule", ["getWarehouseListAction"]),
    beautifyDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return date.toLocaleDateString("en-US", options);
    },
    success(response) {
      console.log(response.data);
      this.$swal({
        title: "Quoted converted!",
        text: "Quote Converted to Proforma Successfully!",
        icon: "success",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then(() => {
        this.$router.push("/proforma/view/" + response.data.result.id);
      });
    },
    error(error) {
      this.$swal({
        title: "Error!",
        text: error.response.data.message,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    },

    async convertProforma() {

      if (this.warehouseId === '') {
        this.showAddWarehouseModal = true;
      } else if (this.continueConversion) {
        this.continueConversion = false;
        this.$swal({
          title: "Loading...",
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            this.$swal.showLoading();
          },
        });
        await api
          .post("quotes/convertion", {
            id: this.id,
            warehouseId: this.warehouseId,
          })
          .then((response) => {
            this.$swal.close();
            console.log(response.data);
            this.success(response);
          })
          .catch((error) => {
            this.$swal.close();
            this.error(error);
          });
      }


    },
    onModalHide() {
    this.continueConversion = false;
  },
    continueConvertProforma() {
      this.showAddWarehouseModal = false;
      this.continueConversion = true;
      this.convertProforma();
    },

    downloadPDF() {
      var opt = {
        margin: [0, 0],
        filename: "quotation.pdf",
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 2, allowTaint: true },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      var element = document.getElementById("invoice-preview-card");
      var elementHtml = element.outerHTML;
      html2pdf().from(elementHtml).set(opt).save();
    },
  },
  async mounted() {
    console.log('Warehouse ID: ', this.warehouseId);
    await this.getWarehouseListAction().then(() => {
      this.warehouse.map((v, i) =>
        this.optionsWarehouse.push({
          value: v.id,
          text: `${v.name}`,
        })
      );
    });
    await this.getQuotationByIdAction(this.id)
      .then(() => {
        this.getLeadByIDAction(this.quotationId.leadClientId)
          .then((response) => {
            this.whenSelected = this.leadId;
          })
          .catch((error) => {
            this.getCustomersByIDAction(this.quotationId.leadClientId).then(
              (response) => {
                this.whenSelected = this.custId;
              }
            );
          });
        this.getSalesPersonListAction().then(() => {
          this.SalesPersonList.forEach((element) => {
            if (element.id == this.quotationId.salesPersonId) {
              this.quotationId.salesPersonId =
                element.firstName + " " + element.lastName;
            }
          });
        });
      })
      .catch((response) => {
        console.log(response);
      });
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss">
@media print {
  a[href]:after {
    content: none !important;
  }

  html,
  body {
    height: 99% !important;
  }

  // Global Styles
  .invoice-date-title {
    color: black !important;
  }

  * {
    color: black !important;
  }

  body {
    background-color: transparent !important;
    font-size: 17.5px !important;
    color: black !important;
  }

  nav.header-navbar {
    display: none;
  }

  .flatpickr-input {
    font-size: 17.5px !important;
    color: black !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  .content-header {
    display: none !important;
  }

  .invoice-edit-input {
    border: none;
  }

  // Invoice Specific Styles
  .invoice-add-wrapper {
    .row.invoice-add {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            >.col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
